import NextLink from 'next/link'
import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { type CrossSellProduct } from '@redteclab/api/clients/retail-media-xsell-service'

import { Link, Rating, Skeleton } from 'base-ui'

import {
  CSW_INTERACTED_KEY,
  useSetSessionStorageValuesForOptimizelyTracking,
} from '../../../experiments/hooks'
import { useGlobalConfigContext } from '../../../global-config'
import { TosSuperscriptHowProductRatingCalculated } from '../../../tos-superscripts'
import { urlGetRelativeUrl } from '../../../url-handling'
import { crossSellAccessorGetLocalizedProductDeepLink } from '../../model/crossSellAccessorGetLocalizedProductDeepLink'
import { crossSellAccessorGetSponsoredProductLink } from '../../model/crossSellAccessorGetSponsoredProductLink'
import { useCrossSellMetadataContext } from '../CrossSellMetatadaProvider'

type CrossSellProductRatingProps = {
  product?: CrossSellProduct
}

export const CrossSellProductRating: FC<CrossSellProductRatingProps> = ({
  product,
}) => {
  const { language, tenant } = useGlobalConfigContext()
  const trackClick =
    useSetSessionStorageValuesForOptimizelyTracking(CSW_INTERACTED_KEY)
  const metadata = useCrossSellMetadataContext()

  if (!product) {
    return (
      <div className="flex flex-col items-center">
        <Skeleton className="w-[106px]" />
        <Skeleton className="mt-2 w-[40px]" />
      </div>
    )
  }

  const rating = product.bewertung_durchschnitt
    ? Math.round(Number(product.bewertung_durchschnitt.replace(',', '.')))
    : 0

  if (rating === 0) {
    return null
  }

  const sponsoredProductLink = crossSellAccessorGetSponsoredProductLink({
    metadata,
    product,
  })
  const href = sponsoredProductLink
    ? `${sponsoredProductLink}#user-reviews-section`
    : `${urlGetRelativeUrl(
        crossSellAccessorGetLocalizedProductDeepLink({
          language,
          product,
          tenant,
        }),
      )}#user-reviews-section`

  return (
    <span className="flex flex-col gap-0.5">
      <span className="flex">
        <Link<typeof NextLink>
          className="flex"
          data-qa-id="product-rating"
          href={href}
          onClick={trackClick}
          rel={sponsoredProductLink ? 'nofollow noopener' : undefined}
          slots={{ root: NextLink }}
        >
          <span className="sr-only">
            <FormattedMessage id="product.rate" values={{ rating }} />
          </span>
          <Rating
            className="text-xl"
            readOnly
            slotProps={{
              iconContainer: {
                className: '-mx-0.5',
              },
            }}
            value={rating}
          />
        </Link>
        <TosSuperscriptHowProductRatingCalculated className="top-1" />
      </span>
      <Link<typeof NextLink>
        className="leading-l text-dark-primary-max"
        data-qa-id="product-rating"
        href={href}
        rel={sponsoredProductLink ? 'nofollow noopener' : undefined}
        slots={{ root: NextLink }}
      >{`(${product.bewertung_anzahl})`}</Link>
    </span>
  )
}
